const Simulator = () => {
  return (
    <div>
      <h2>Simulador</h2>
      <p>AOBA</p>
    </div>
  );
};

export default Simulator;
