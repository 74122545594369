import { useCallback, useMemo, useState } from 'react';
import { ToggleGroupItem } from '../../../../ui/toggle-group';
import { Profile } from '../../../../../interfaces/Profile';
import { LoaderCircle, Minus, Pencil, Plus, Trash } from 'lucide-react';
import { useFetch } from '../../../../../hooks/use-fetch';
import ProfileInput from './ProfileInput';

const iconStyles = 'opacity-75 hover:opacity-100 transition-opacity';
const ProfileOption = ({
  profile,
  selectedProfiles,
}: {
  profile: Profile;
  selectedProfiles: string[];
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [changeProfile, loadingChange] = useFetch<Profile[]>(
    '/profiles',
    'POST'
  );
  const [deleteProfile, loadingDelete] = useFetch<Profile[]>(
    `/profiles?id=${profile.id}`,
    'DELETE'
  );

  const loading = useMemo(
    () => loadingChange || loadingDelete,
    [loadingChange, loadingDelete]
  );

  const isSelected = useCallback(
    (profile: Profile) => selectedProfiles.includes(profile.id.toString()),
    [selectedProfiles]
  );

  const selectProfile = useCallback(() => {
    const ev = new CustomEvent('profile-deselected', {
      detail: profile.id.toString(),
    });
    window.dispatchEvent(ev);
  }, [profile]);

  const deselectProfile = useCallback(() => {
    const ev = new CustomEvent('profile-selected', {
      detail: profile.id.toString(),
    });
    window.dispatchEvent(ev);
  }, [profile]);

  const deleteAndUpdateProfiles = useCallback(() => {
    deleteProfile().then((res) => {
      window.dispatchEvent(
        new CustomEvent('new-profiles', {
          detail: res.data,
        })
      );
    });
  }, [deleteProfile]);

  return editMode ? (
    <div className="w-fit">
      <ProfileInput
        open={editMode}
        onOpenChange={setEditMode}
        profile={profile}
        f={changeProfile}
        loading={loading}
      />
    </div>
  ) : (
    <ToggleGroupItem
      key={profile.id}
      variant="outline"
      value={profile.id.toString()}
      className="data-[state=on]:bg-azblue data-[state=on]:text-white relative px-5 min-w-[25%] px-1"
      onMouseOver={() => setShowOptions(!editMode && true)}
      onMouseOut={() => setShowOptions(false)}
      disabled={loading}
    >
      {showOptions ? (
        <div
          className="flex items-center justify-center absolute gap-3 animate-in fade-in
      bg-black/75 w-full h-full rounded-md text-white backdrop-blur-[1px] px-1"
        >
          {isSelected(profile) ? (
            <Minus onClick={selectProfile} className={iconStyles} />
          ) : (
            <Plus onClick={deselectProfile} className={iconStyles} />
          )}
          <Pencil
            className={iconStyles}
            onClick={() => {
              setShowOptions(false);
              setEditMode(true);
            }}
          />
          <Trash className={iconStyles} onClick={deleteAndUpdateProfiles} />
        </div>
      ) : null}
      {loading ? (
        <>
          <span className="flex gap-2 items-center">
            {loadingDelete ? 'Deletando' : 'Salvando'}
            <LoaderCircle className={iconStyles + ' animate-spin'} />
          </span>
        </>
      ) : (
        profile.profile_name
      )}
    </ToggleGroupItem>
  );
};

export default ProfileOption;
