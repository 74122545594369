import React from 'react';
import logo from '../../../assets/img/AZCONEX_LOGO.png';

const Header = () => {
  return (
    <>
      <div className="flex flex-col items-center gap-2">
        <div className="flex items-center gap-2.5">
          <h1>Portal</h1>
          <img src={logo} alt="Logo - AZConeX" className="max-h-16" />
        </div>
        <p>Insira seu e-mail ou chave AZConeX para acessar o portal</p>
      </div>
    </>
  );
};

export default Header;
