import { useEffect } from 'react';

function useEvent<T>(
  eventName: string,
  cb: (ev: CustomEvent<T>) => void
): void {
  useEffect(() => {
    window.addEventListener(eventName, cb as EventListener);
    return () => {
      window.removeEventListener(eventName, cb as EventListener);
    };
  }, [eventName, cb]);
}

export { useEvent };
