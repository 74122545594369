import React from 'react';
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
  FormField as Field,
} from '../../ui/form';
import { Input } from '../../ui/input';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';

const FormField = <T extends FieldValues>({
  form,
  label,
  name,
  placeholder = '',
}: {
  form: UseFormReturn<T>;
  label: string;
  name: Path<T>;
  placeholder?: string;
}) => (
  <Field
    control={form.control}
    name={name}
    render={({ field }) => (
      <FormItem>
        <FormLabel>{label}</FormLabel>
        <FormControl>
          <Input
            type={label === 'Senha' ? 'password' : undefined}
            placeholder={placeholder}
            {...field}
            className="bg-[#A3AAC2] text-white placeholder:text-white"
          />
        </FormControl>
        <FormMessage />
      </FormItem>
    )}
  />
);

export default FormField;
