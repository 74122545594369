import { Route, Routes } from 'react-router-dom';
import Simulator from './Simulator';

const Deals = () => {
  return (
    <div>
      <p>Hello deals!</p>
      <Routes>
        <Route path="/simulator" element={<Simulator />} />
      </Routes>
    </div>
  );
};

export default Deals;
