import React from 'react';
import lowOpLogo from '../../../../../assets/img/logo-AZCONEX-cinza-25op.svg';

const Root = (): JSX.Element => (
  <div className="flex flex-col gap-2.5 items-center m-auto w-1/2 h-full justify-center">
    <img src={lowOpLogo} alt="Logo da AZConeX com baixa opacidade" />
    <p className="text-lg text-center text-[#989898]">
      Nenhum menu selecionado
    </p>
  </div>
);

export default Root;
