import { z } from 'zod';

const formSchema = z
  .object({
    id: z.number().nullable(),
    menu_name: z.string().min(1, 'Campo obrigatório!').max(50),
    route: z.string().regex(/^\/{1}\w+/, 'Rota inválida!'),
    module_name: z.string().nullable(),
    menu_icon: z.string().min(1, 'Campo obrigatório!'),
    profiles: z.string().array(),
    parent_menu: z.string().nullable(),
  })
  .refine((data) =>
    !data.parent_menu
      ? data.module_name && /^[A-Z]{1}\w*$/.test(data.module_name)
      : true
  );

export default formSchema;
