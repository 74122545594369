import React from 'react';
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../../../ui/dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { ScrollArea } from '../../../ui/scroll-area';

const IconSelector = ({
  dialogContent,
  iconsList,
}: {
  dialogContent: React.MutableRefObject<null>;
  iconsList: JSX.Element[];
}) => {
  return (
    <DialogContent ref={dialogContent} style={{ animationName: 'none' }}>
      <DialogHeader>
        <DialogTitle className="mb-4">Escolha um ícone</DialogTitle>
        <VisuallyHidden>
          <DialogDescription>
            Painel para seleção de ícone do menu.
          </DialogDescription>
        </VisuallyHidden>
      </DialogHeader>
      <ScrollArea className="max-h-[50vh]">
        <div className="flex flex-wrap gap-5">{iconsList}</div>
      </ScrollArea>
    </DialogContent>
  );
};

export default IconSelector;
