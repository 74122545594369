import React from 'react';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../ui/form';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { Input } from '../../../../ui/input';
import { Button } from '../../../../ui/button';
import { DialogTrigger } from '../../../../ui/dialog';
import { icons } from 'lucide-react';

const IconFormField = <T extends FieldValues>({
  form,
  iconName,
  iconSetter,
}: {
  form: UseFormReturn<T>;
  iconName: string | null;
  iconSetter: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
  const Icon = iconName ? (icons as { [p: string]: any })[iconName] : null;
  return (
    <FormField
      control={form.control}
      name={'menu_icon' as Path<T>}
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel className="font-semibold">Ícone</FormLabel>
          <div className="!mt-0 flex justify-between items-center gap-2">
            <FormControl>
              <Input {...field} type="text" className="hidden" />
            </FormControl>
            {iconName && Icon ? (
              <Button
                variant="ghost"
                size="icon"
                className="[&_svg]:size-8 relative"
                title={iconName}
              >
                <Icon />
              </Button>
            ) : (
              <p className="block text-sm text-center">
                Nenhum ícone selecionado
              </p>
            )}

            <div className="flex gap-2 items-center">
              <DialogTrigger asChild>
                <Button type="button" size="sm">
                  {!!iconName ? 'Alterar ícone' : 'Selecionar ícone'}
                </Button>
              </DialogTrigger>
              {!!iconName ? (
                <Button
                  onClick={() => iconSetter(null)}
                  variant="destructive"
                  size="sm"
                >
                  Redefinir ícone
                </Button>
              ) : null}
            </div>
          </div>
          <FormMessage />
        </FormItem>
      )}
    ></FormField>
  );
};

export default IconFormField;
