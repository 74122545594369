/* eslint-disable @typescript-eslint/no-unused-vars */
import Users from './components/Modules/Users/Users';
import Deals from './components/Modules/Deals/Deals';

export type Module = { [p: string]: () => JSX.Element };

const Modules: Module = {
  Users,
  Deals,
};

export default Modules;
