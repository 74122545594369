import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import Logo from '../../../../../assets/img/logo-AZCONEX-branca.svg';

import formSchema from './formSchema';

import { default as FormField } from './FormField';
import { z } from 'zod';
import Root from './Root';
import { Form } from '../../../../ui/form';
import IconFormField from './IconFormField';
import { MenuSelectedContext, ProfilesLoadedContext } from '../MenuEditor';
import ProfileSelector from './ProfileSelector';
import { Button } from '../../../../ui/button';
import { useFetch } from '../../../../../hooks/use-fetch';
import { SetUserContext, UserContext } from '../../../../../App';
import Menu from '../../../../../interfaces/Menu';
import User from '../../../../../interfaces/User';
import { Toaster } from '../../../../ui/toaster';
import { Profile } from '../../../../../interfaces/Profile';
import { toast } from '../../../../../hooks/use-toast';
import { node } from '../types';

const MenuForm = ({
  icon,
  setIcon,
  setProfiles,
  setMenuSelected,
}: {
  icon: string | null;
  setIcon: React.Dispatch<React.SetStateAction<string | null>>;
  setProfiles: React.Dispatch<React.SetStateAction<Profile[]>>;
  setMenuSelected: React.Dispatch<React.SetStateAction<node>>;
}) => {
  const user = useContext(UserContext) as User;
  const setUser = useContext(SetUserContext)!;
  const [parent, child] = useContext(MenuSelectedContext);
  const profiles = useContext(ProfilesLoadedContext);

  const [saveChanges, loading] = useFetch<{
    menu_tree: Menu[];
    new_menu: Menu;
  }>('/menu', 'PUT');
  const [getProfiles] = useFetch<Profile[]>('/profiles');

  const iconName = useMemo(() => icon ?? child?.menu_icon, [child, icon]);

  const defaultValues: z.infer<typeof formSchema> = useMemo(() => {
    return {
      id: child?.id ?? null,
      menu_name: child?.menu_name ?? '',
      route: child?.route ?? '',
      module_name: child?.module_name ?? '',
      menu_icon: child?.menu_icon ?? '',
      profiles:
        child && profiles
          ? profiles
              .filter((profile) => profile.menus.includes(child.id))
              .map((profile) => profile.id.toString())
          : [],
      parent_menu: parent && parent !== 'root' ? parent?.menu_name : null,
    };
  }, [profiles, parent, child]);

  const updateUser = useCallback(
    (menuTree: Menu[]) => {
      setUser({ ...user, menus: menuTree });
      window.sessionStorage.setItem('user', JSON.stringify(user));
    },
    [user, setUser]
  );

  const updateMenuSelected = useCallback(
    (newMenu: Menu) => {
      setMenuSelected([
        parent!,
        {
          ...child!,
          menu_name: newMenu.menu_name,
          menu_icon: newMenu.menu_icon,
          module_name: newMenu.module_name,
          route: newMenu.route,
        },
      ]);
    },
    [parent, child, setMenuSelected]
  );

  const [selectedProfiles, setSelectedProfiles] = useState<string[]>([]);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  useEffect(() => {
    form.reset(defaultValues);
    setSelectedProfiles(defaultValues.profiles);

    return () => {
      setIcon(null);
      for (const key in defaultValues) {
        let property = key as keyof typeof defaultValues;
        if (Array.isArray(defaultValues[property])) {
          (defaultValues[property] as string[]) = [];
          continue;
        }
        (defaultValues[property] as string) = '';
      }
    };
  }, [form, defaultValues, setIcon]);

  useEffect(
    () => form.setValue('menu_icon', icon ?? defaultValues.menu_icon),
    [icon, child, form, defaultValues]
  );

  function onSubmit(values: z.infer<typeof formSchema>) {
    // Save changes in the database and receive new menu data on success
    saveChanges(values)
      .then((res) => {
        // Update User object in session storage and in context with new menu tree
        const { new_menu, menu_tree } = res!.data;

        const profilesNotChanged =
          defaultValues.profiles.every((profile) =>
            values.profiles.includes(profile)
          ) &&
          values.profiles.every((profile) =>
            defaultValues.profiles.includes(profile)
          );

        toast({
          title: 'Menu salvo com sucesso!',
          description: profilesNotChanged
            ? ''
            : 'Atualizando perfis em árvore de menus...',
        });

        // Update profiles through menu tree
        if (!profilesNotChanged)
          getProfiles()
            .then((res) => {
              setProfiles(res.data);
              const t = toast({ description: 'Árvore atualizada.' });
              setTimeout(() => t.dismiss(), 2000);
            })
            .catch((e) => console.error(e))
            .finally(() => {
              updateMenuSelected(new_menu);
              updateUser(menu_tree);
            });
        else {
          updateMenuSelected(new_menu);
          updateUser(menu_tree);
        }
      })
      .catch((e) => {
        toast({
          title: 'Erro ao salvar o menu.',
          description: e.msg,
          variant: 'destructive',
          className: 'data-[state=closed]:slide-out-to-bottom-full',
        });
      });
  }

  return (
    <>
      {parent ? (
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="py-3 px-8 h-[calc(100%-1.5rem)]"
          >
            <Toaster />
            <div className="box-border grid grid-rows-[min-content_min-content_min-content_auto_max-content] gap-3 h-full">
              <FormField
                form={form}
                menu={child!}
                name="menu_name"
                displayName="Nome"
                menuProp="menu_name"
              />
              <FormField
                form={form}
                menu={child!}
                name="route"
                displayName="Rota"
                menuProp="route"
                placeholder="/recurso"
              />
              {parent === 'root' ? (
                <FormField
                  form={form}
                  menu={child!}
                  name="module_name"
                  displayName="Nome do Módulo"
                  menuProp="module_name"
                  placeholder="NomeDoMódulo"
                />
              ) : null}
              <IconFormField
                form={form}
                iconName={iconName!}
                iconSetter={setIcon}
              />
              <ProfileSelector
                form={form}
                selected={selectedProfiles}
                selector={setSelectedProfiles}
              />
              <Button className="w-fit justify-self-end self-end">
                {loading ? (
                  <div className="flex gap-2.5">
                    <img
                      src={Logo}
                      alt="Logo da AZConeX girando em sentido horário"
                      className="motion-safe:animate-[spin_3s_linear_infinite] w-4"
                    />
                    <p>Salvando menu...</p>
                  </div>
                ) : (
                  'Salvar'
                )}
              </Button>
            </div>
          </form>
        </Form>
      ) : (
        <Root />
      )}
    </>
  );
};

export default MenuForm;
