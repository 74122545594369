import { ChevronRight } from 'lucide-react';
import * as icons from 'lucide-react';

import { Button } from '../../../ui/button';
import Menu from '../../../../interfaces/Menu';
import { Link } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

type lucideIcons = { [p: string]: any };

const Submenu = ({
  parent_route = '',
  menu,
  level = 0,
  submenus,
  menuSelected,
  setMenuSelected,
  cn = '',
}: {
  parent_route?: string;
  menu: Menu;
  level?: number;
  submenus: Menu[];
  menuSelected: number[];
  setMenuSelected: React.Dispatch<React.SetStateAction<number[]>>;
  cn?: string;
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const MenuIcon = (icons as lucideIcons)[menu.menu_icon];

  useEffect(() => {
    setTimeout(() => setIsSelected(menuSelected.includes(menu.id)), 150);
  }, [menuSelected, menu]);

  const hasSubmenus = useMemo(() => !!menu.submenus, [menu]);
  const isActive = useMemo(
    () =>
      window.location.pathname.includes(menu.route) &&
      !(hasSubmenus && menuSelected.includes(menu.id)),
    [menuSelected, menu, hasSubmenus]
  );
  const animationClasses = useMemo(
    () =>
      menuSelected.includes(menu.id)
        ? 'animate-in fade-in slide-in-from-left'
        : 'animate-out fade-out slide-out-to-left',
    [menuSelected, menu]
  );

  return (
    <ul
      key={menu.menu_name}
      className={'flex flex-col ' + cn}
      style={{
        marginLeft: 16 * level,
        animationFillMode: 'forwards',
        animationDuration: '150ms',
      }}
    >
      <div className="flex">
        <Button
          key={menu.menu_name}
          className={
            'justify-start px-1' +
            (isActive
              ? ' bg-azblue text-white hover:bg-azblue hover:text-white'
              : '')
          }
          size="sm"
          variant="ghost"
          onClick={() => {
            const submenusIds = menu.submenus?.map((submenu) => submenu.id);
            isSelected
              ? setTimeout(
                  () =>
                    setMenuSelected([
                      ...menuSelected
                        .filter((id) => id !== menu.id)
                        .filter((id) => !submenusIds?.includes(id)),
                    ]),
                  150
                )
              : setMenuSelected([...menuSelected, menu.id]);
          }}
        >
          <MenuIcon />
          {hasSubmenus ? (
            <span>{menu.menu_name}</span>
          ) : (
            <Link to={parent_route + menu.route}>{menu.menu_name}</Link>
          )}

          {hasSubmenus ? (
            <ChevronRight
              className={
                'transition-transform ' +
                (menuSelected.includes(menu.id) ? 'rotate-90' : 'rotate-0')
              }
            />
          ) : null}
        </Button>
      </div>

      {isSelected
        ? [
            menu.submenus?.map((submenu) => (
              <Submenu
                key={submenu.menu_name}
                parent_route={parent_route + menu.route}
                menu={submenu}
                level={level + 1}
                submenus={submenus}
                menuSelected={menuSelected}
                setMenuSelected={setMenuSelected}
                cn={animationClasses}
              />
            )),
          ]
        : null}
    </ul>
  );
};

export default Submenu;
