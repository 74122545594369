import { useContext, useState } from 'react';
import {
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
} from '../../ui/alert-dialog';
import { useEvent } from '../../../hooks/use-event';
import { SetUserContext } from '../../../App';

const NewLoginRequestAlert = () => {
  const [newLoginRequest, setNewLoginRequest] = useState(false);
  const setUser = useContext(SetUserContext)!;

  useEvent('new-login-request', () => setNewLoginRequest(true));
  return (
    <AlertDialog open={newLoginRequest} onOpenChange={setNewLoginRequest}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Sua sessão expirou!</AlertDialogTitle>
          <AlertDialogDescription>
            Por favor, faça <i>login</i> novamente.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction
            onClick={() => {
              setUser(null);
              window.sessionStorage.removeItem('user');
              setNewLoginRequest(false);
              window.location.reload();
            }}
          >
            OK
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default NewLoginRequestAlert;
