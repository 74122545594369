import { useCallback, useEffect, useState } from 'react';
import { useFetch } from './use-fetch';
import User from '../interfaces/User';

let sessionStarted = true;

function useSession(): [
  User | null,
  React.Dispatch<React.SetStateAction<User | null>>,
  boolean,
  boolean
] {
  const [validateUser, loading, setLoading] = useFetch<User>(
    '/session_validate',
    'GET',
    true,
    {},
    true
  );
  const [user, setUser] = useState<User | null>(null);
  const [sessionState, setSessionState] = useState(true);

  const validateUserCB = useCallback(() => {
    validateUser()
      .then((res) => {
        if (!res.ok) {
          window.sessionStorage.removeItem('user');
          setUser(null);
          return;
        }

        const userData = res.data;
        const json = JSON.stringify(userData);
        window.sessionStorage.setItem('user', json);
        setUser(userData);
      })
      .finally(() => setLoading(false));
  }, [validateUser, setLoading]);

  useEffect(() => {
    if (sessionStarted) {
      sessionStarted = false;
      setSessionState(false);
      if (window.sessionStorage.getItem('user')) validateUserCB();
    }
  }, [validateUserCB, setLoading]);

  return [user, setUser, loading, sessionState];
}

export { useSession };
