import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import * as icons from 'lucide-react';

import { Button } from '../../../ui/button';
import { UserContext } from '../../../../App';
import User from '../../../../interfaces/User';
import { Dialog, DialogTrigger } from '../../../ui/dialog';
import MenuEditor from '../MenuEditor/MenuEditor';
import Submenu from './Submenu';
type lucideIcons = { [p: string]: any };

const MenuTree = () => {
  const user = useContext(UserContext) as User;
  const [menuSelected, setMenuSelected] = useState<number[]>([]);
  const menuTreeWrapper = useRef<HTMLDivElement>(null);
  const menuList = useRef<HTMLUListElement>(null);
  const [width, setWidth] = useState<number>();

  const menuTree = useMemo(
    () =>
      user.menus?.map((menu) => (
        <Submenu
          key={menu.id}
          menu={menu}
          submenus={menu.submenus}
          menuSelected={menuSelected}
          setMenuSelected={setMenuSelected}
        />
      )),
    [user, menuSelected]
  );

  useEffect(() => {
    if (menuTreeWrapper.current) {
      const observer = new MutationObserver(([record]) => {
        if (record.type === 'childList') {
          console.log(
            record.target.parentElement?.getBoundingClientRect().width
          );

          if (menuList.current)
            setWidth(
              record.target.parentElement!.getBoundingClientRect().width + 32
            );
        }
      });
      observer.observe(menuTreeWrapper.current, {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true,
      });
      return () => observer.disconnect();
    }
  }, []);

  const Settings = (icons as lucideIcons)['Settings'];
  return (
    <Dialog>
      <ul
        ref={menuList}
        className="flex flex-col shadow-lg p-2 min-h-screen transition-[width] linear duration-150"
        style={{ width: width + 'px' || 'fit-content' }}
      >
        <div ref={menuTreeWrapper} className="menu-tree-wrapper w-fit">
          {menuTree}
        </div>
        {user.profiles.includes('Desenvolvedor') && (
          <DialogTrigger asChild>
            <div className="flex">
              <Button variant="ghost" className="px-1 justify-start">
                <Settings className="h-4 w-4" />
                Editar menu
              </Button>
            </div>
          </DialogTrigger>
        )}
      </ul>
      <MenuEditor />
    </Dialog>
  );
};

export default MenuTree;
