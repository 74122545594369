import React, { createContext } from 'react';
import './assets/fonts/stylesheet.css';
import './App.css';

import Header from './components/Container/Portal/Header';
import Viewport from './components/Container/Portal/Viewport';
import User from './interfaces/User';
import Login from './components/Container/Login/Login';
import { BrowserRouter } from 'react-router-dom';
import MenuTree from './components/Container/Portal/Menu/MenuTree';
import Logo from './assets/img/logo-AZCONEX-branca.svg';
import { useSession } from './hooks/use-session';
import NewLoginRequestAlert from './components/Container/Portal/NewLoginRequestAlert';

export const SetUserContext = createContext<React.Dispatch<
  React.SetStateAction<User | null>
> | null>(null);
export const UserContext = createContext<User | null>(null);

const App = () => {
  const [user, setUser, loading, sessionState] = useSession();

  if (loading || sessionState)
    return (
      <div className="bg-azblue text-center flex flex-col w-screen h-screen m-auto justify-center items-center text-white relative">
        <p className="text-xl">Por favor, aguarde...</p>
        <img
          src={Logo}
          alt="Logo da AZConeX girando em sentido horário"
          className="motion-safe:animate-[spin_30s_linear_infinite] w-screen absolute opacity-50 blur-[2px]"
        />
      </div>
    );

  return (
    <SetUserContext.Provider value={setUser}>
      {user ? (
        <UserContext.Provider value={user}>
          {/**Opens only when the user's session expires */}
          <NewLoginRequestAlert />
          <Header />
          <BrowserRouter>
            <div className="wrapper flex gap-2.5 max-w-[100vw]">
              <MenuTree />
              <Viewport />
            </div>
          </BrowserRouter>
        </UserContext.Provider>
      ) : (
        <Login />
      )}
    </SetUserContext.Provider>
  );
};

export default App;
