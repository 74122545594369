import React, { SyntheticEvent, useState } from 'react';
import { Button } from '../../../../ui/button';
import { LoaderCircle, Save, X } from 'lucide-react';
import { Input } from '../../../../ui/input';
import { Profile } from '../../../../../interfaces/Profile';
import IResponse from '../../../../../interfaces/Response';

const ProfileInput = ({
  profile = null,
  open = false,
  onOpenChange,
  f,
  loading,
}: {
  profile?: Profile | null;
  open: boolean;
  onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
  f: (
    body?: Record<any, string | number | object | null>
  ) => Promise<IResponse<Profile[]>>;
  loading: boolean;
}) => {
  const [profileName, setProfileName] = useState<string>(
    profile ? profile.profile_name : ''
  );

  const saveChanges = (e: SyntheticEvent) => {
    e.preventDefault();

    if (profileName?.length)
      f({ id: profile?.id || '', profile_name: profileName })
        .then((res) => {
          window.dispatchEvent(
            new CustomEvent('new-profiles', { detail: res.data })
          );
          setProfileName('');
          onOpenChange(false);
        })
        .catch((e) => console.error(e));
  };

  return open ? (
    <div className="w-full flex gap-2">
      <Input
        disabled={loading}
        className="border"
        placeholder="Insira o nome do perfil"
        value={profileName}
        onInput={(e) => setProfileName(e.currentTarget.value)}
      />
      <div className="flex gap-2">
        <Button size="icon" variant="default" onClick={saveChanges}>
          {loading ? (
            <LoaderCircle className="motion-safe:animate-spin" />
          ) : (
            <Save />
          )}
        </Button>
        <Button
          disabled={loading}
          size="icon"
          variant="destructive"
          onClick={() => onOpenChange(false)}
        >
          <X />
        </Button>
      </div>
    </div>
  ) : null;
};

export default ProfileInput;
