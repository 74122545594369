import React from 'react';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../ui/form';
import { Input } from '../../../../ui/input';
import Menu from '../../../../../interfaces/Menu';

const MenuEditorFormField = <T extends FieldValues>({
  form,
  name,
  displayName,
  placeholder = '',
}: {
  form: UseFormReturn<T>;
  menu: Menu | null;
  name: Path<T>;
  displayName: string;
  placeholder?: string;
  menuProp: keyof Menu;
}) => {
  return (
    <FormField
      key={name}
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="gap-2">
          <FormLabel className="font-semibold">{displayName}</FormLabel>
          <FormControl>
            <Input
              {...field}
              type="text"
              className="!mt-0 border"
              placeholder={placeholder}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    ></FormField>
  );
};

export default MenuEditorFormField;
