import React, { useContext, useState } from 'react';
import Menu from '../../../../interfaces/Menu';
import { Button } from '../../../ui/button';
import { useFetch } from '../../../../hooks/use-fetch';
import { SetUserContext, UserContext } from '../../../../App';
import User from '../../../../interfaces/User';
import { LoaderCircle } from 'lucide-react';

const DeletionModal = ({
  menu,
  show = false,
  setDisplay,
}: {
  menu: Menu;
  show?: boolean;
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const user = useContext(UserContext) as User;
  const setUser = useContext(SetUserContext)!;
  const [deleteMenu, loading] = useFetch<Menu[]>(
    `/menu?id=${menu.id}`,
    'DELETE'
  );
  const [hasError, setHasError] = useState<boolean>(false);

  const handleDeletion = () => {
    deleteMenu()
      .then((res) => {
        user.menus = res.data;
        setUser({ ...user });
        window.sessionStorage.setItem('user', JSON.stringify(user));
        setDisplay(false);
      })
      .catch(() => setHasError(true));
  };

  return show ? (
    <div className="h-full w-full flex items-center justify-center absolute top-0 left-0 bg-white/50 z-20 animate-in zoom-in ease-out">
      <div
        className={
          'rounded-lg shadow-lg p-5 absolute text-sm border text-center m-auto ' +
          (hasError ? 'bg-destructive text-destructive-foreground' : 'bg-white')
        }
      >
        {hasError ? (
          <>
            <p>Erro de servidor!</p>
            <p>Não foi possível completar a sua solicitação.</p>
            <div className="flex flex-row-reverse">
              <Button
                variant="ghost"
                onClick={() => {
                  setDisplay(false);
                  setHasError(false);
                }}
                className="mt-2.5 self-end"
              >
                Fechar
              </Button>
            </div>
          </>
        ) : loading ? (
          <p className="flex flex-col gap-2 items-center">
            Deletando menu...
            <LoaderCircle className="mr-2 h-4 w-4 motion-safe:animate-spin" />
          </p>
        ) : (
          <>
            <p>
              Tem certeza que deseja deletar o menu <b>{menu.menu_name}</b>?
            </p>
            {menu.submenus ? (
              <>
                {menu.submenus.length > 1 ? (
                  <p>
                    'Também serão excluídos{' '}
                    <b>{menu.submenus.length} submenus</b>.
                  </p>
                ) : (
                  <p>Também será excluído 1 submenu.</p>
                )}
              </>
            ) : null}
            <p className="font-bold">Essa ação é irreversível!</p>
            <div className="flex gap-10 mt-2.5 justify-evenly">
              <Button onClick={() => handleDeletion()}>Confirmar</Button>
              <Button
                variant="destructive"
                onClick={() => {
                  setDisplay(false);
                }}
              >
                Cancelar
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  ) : null;
};

export default DeletionModal;
