import React, { useContext, useEffect, useState } from 'react';
import { MenuSelectedContext, ProfilesLoadedContext } from '../MenuEditor';
import { ToggleGroup } from '../../../../ui/toggle-group';
import { ScrollArea, ScrollBar } from '../../../../ui/scroll-area';
import { UseFormReturn } from 'react-hook-form';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '../../../../ui/form';
import ProfileOption from './ProfileOption';
import { Plus } from 'lucide-react';
import { Button } from '../../../../ui/button';
import { useFetch } from '../../../../../hooks/use-fetch';
import { useEvent } from '../../../../../hooks/use-event';
import ProfileInput from './ProfileInput';
import { Profile } from '../../../../../interfaces/Profile';
import formSchema from './formSchema';
import { z } from 'zod';

const ProfileSelector = ({
  form,
  selected,
  selector,
}: {
  form: UseFormReturn<z.infer<typeof formSchema>>;
  selected: string[];
  selector: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const [addProfile, setAddProfile] = useState(false);
  const [parent, menu] = useContext(MenuSelectedContext);
  const profiles = useContext(ProfilesLoadedContext);
  const [saveProfile, loading] = useFetch<Profile[]>('/profiles', 'PUT');

  useEvent<string>('profile-selected', (e) => {
    selector([...selected, e.detail]);
  });

  useEvent<string>('profile-deselected', (e) => {
    selector(selected.filter((s) => s !== e.detail));
  });

  useEffect(() => form.setValue('profiles', selected), [selected, form]);

  const profilesList = profiles
    .filter(
      (profile) =>
        parent &&
        (parent === 'root') !==
          (parent !== 'root' && profile.menus.includes(parent.id)) &&
        profile.profile_name !== 'Desenvolvedor'
    )
    .map((profile) => (
      <ProfileOption
        profile={profile}
        selectedProfiles={selected}
        key={profile.id}
      />
    ));

  return (
    <FormField
      key={menu?.menu_name}
      control={form.control}
      name="profiles"
      render={({ field }) => (
        <FormItem className="grid grid-rows-subgrid h-fit">
          <div className="grid grid-rows-[min-content_1fr_min-content] gap-2">
            <FormLabel className="font-semibold">Perfis autorizados</FormLabel>
            <FormControl>
              <ScrollArea className="border-y-[1px] border-neutral-300 py-2">
                <ToggleGroup
                  {...field}
                  type="multiple"
                  className="flex flex-wrap gap-2 justify-start"
                >
                  {profilesList}
                </ToggleGroup>
                <ScrollBar color="blue" orientation="vertical" />
              </ScrollArea>
            </FormControl>
            {addProfile ? (
              <ProfileInput
                open={addProfile}
                onOpenChange={setAddProfile}
                f={saveProfile}
                loading={loading}
              />
            ) : (
              <Button
                type="button"
                variant="outline"
                className="border-azblue w-fit"
                onClick={() => setAddProfile(true)}
              >
                <Plus />
                Adicionar perfil
              </Button>
            )}
          </div>
        </FormItem>
      )}
    ></FormField>
  );
};

export default ProfileSelector;
