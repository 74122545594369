import React from 'react';
import logo from '../../../assets/img/AZCONEX_LOGO.png';
import { User, Bell } from 'lucide-react';

import { Button } from '../../ui/button';

const Header = () => (
  <header
    className="container columns-4 
                        gap-8 px-4 pt-2 pb-4 items-center grid grid-cols-3 
                        mx-auto max-w-[100vw]"
  >
    <img className="max-w-48" src={logo} alt="Logo - AZConeX" />
    <div className="buttons flex gap-x-5 col-start-3 items-end justify-self-end">
      <Button variant="ghost" size="icon">
        <Bell className="h-6 w-6" />
      </Button>
      <Button variant="ghost" size="icon">
        <User className="h-6 w-6" />
      </Button>
    </div>
  </header>
);

export default Header;
