import { createContext, useContext, useMemo, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '../../../ui/dialog';
import {
  ResizablePanelGroup,
  ResizablePanel,
  ResizableHandle,
} from '../../../ui/resizable';
import { UserContext } from '../../../../App';
import User from '../../../../interfaces/User';
import { LoaderCircle, Plus } from 'lucide-react';
import { Button } from '../../../ui/button';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import Subtree from './Subtree';
import MenuForm from './MenuForm/MenuForm';
import { icons } from 'lucide-react';
import { node } from './types';
import { useFetch } from '../../../../hooks/use-fetch';
import { Profile } from '../../../../interfaces/Profile';
import IconSelector from './IconSelector';
import { useEvent } from '../../../../hooks/use-event';

export const MenuSelectedContext = createContext<node>([]);
export const ProfilesLoadedContext = createContext<Profile[]>([]);

const MenuEditor = () => {
  const user = useContext(UserContext) as User;

  const [subtrees, setSubtrees] = useState<string[]>([]);
  const [menuSelected, setMenuSelected] = useState<node>([]);
  const [icon, setIcon] = useState<string | null>(null);
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const dialogContent = useRef(null);
  const [getProfiles, loading] = useFetch<Profile[]>('/profiles');

  useEvent<Profile[]>('new-profiles', (e) => setProfiles(e.detail));

  const iconsList = useMemo(
    () =>
      Object.keys(icons).map((k: string) => {
        const Icon = (icons as { [p: string]: any })[k];
        return (
          <Button
            key={k}
            variant="ghost"
            size="sm"
            title={k}
            onClick={() => {
              setIcon(k);
              (
                (dialogContent.current! as HTMLDivElement)
                  .lastElementChild as HTMLButtonElement
              ).click();
            }}
          >
            <Icon />
          </Button>
        );
      }),
    [dialogContent, setIcon]
  );

  const tree = useMemo(
    () => [
      ...user.menus.map((menu) => (
        <Subtree
          key={menu.id}
          menu={menu}
          subtrees={subtrees}
          subtreesSetter={setSubtrees}
          menuSelected={menuSelected}
          menuSelectedSetter={setMenuSelected}
        />
      )),
      <Button
        key="root_add"
        variant="ghost"
        size="sm"
        className={
          'justify-start  px-1 font-semibold w-full' +
          (menuSelected[0] === 'root' && !menuSelected[1]
            ? ' bg-azblue text-white hover:bg-azblue hover:text-white'
            : '')
        }
        onClick={() => setMenuSelected(['root', null])}
      >
        <Plus /> Adicionar menu
      </Button>,
    ],
    [user, subtrees, menuSelected]
  );

  return (
    <MenuSelectedContext.Provider value={menuSelected}>
      <DialogContent
        onOpenAutoFocus={() => {
          getProfiles()
            .then((res) => setProfiles(res.data))
            .catch((e) => console.error(e));
        }}
        onCloseAutoFocus={() => {
          setMenuSelected([]);
          setIcon(null);
          setProfiles([]);
        }}
        className="h-[90vh] max-w-[75vw] w-[clamp(50vw,_720px,_90vw)]"
      >
        <VisuallyHidden>
          <DialogTitle>Painel de gerenciamento de menu</DialogTitle>
          <DialogDescription>
            Painel para adição/atualização de menus por desenvolvedores.
          </DialogDescription>
        </VisuallyHidden>
        <Dialog>
          {loading ? (
            <div className="bg-white/80 backdrop-blur-[2px] absolute w-full h-full flex items-center justify-center z-20">
              <LoaderCircle className="mr-2 h-4 w-4 motion-safe:animate-spin" />
              Carregando perfis...
            </div>
          ) : null}
          <ResizablePanelGroup direction="horizontal">
            <ResizablePanel minSize={30} defaultSize={40}>
              <h2 className="text-2xl font-semibold">Árvore de menus</h2>
              <div className="border-t-2 pt-2">{tree}</div>
            </ResizablePanel>
            <ResizableHandle withHandle={true} />
            <ResizablePanel minSize={50} defaultSize={60}>
              <h2 className="text-2xl font-semibold pl-2">
                Criação/edição de menus
              </h2>
              <ProfilesLoadedContext.Provider value={profiles}>
                <MenuForm
                  icon={icon}
                  setIcon={setIcon}
                  setProfiles={setProfiles}
                  setMenuSelected={setMenuSelected}
                />
              </ProfilesLoadedContext.Provider>
            </ResizablePanel>
          </ResizablePanelGroup>
          <IconSelector dialogContent={dialogContent} iconsList={iconsList} />
        </Dialog>
      </DialogContent>
    </MenuSelectedContext.Provider>
  );
};

export default MenuEditor;
