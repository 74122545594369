import React from 'react';

import logo from '../../../assets/img/logo-AZCONEX-branca.svg';
import LoginForm from './LoginForm';

const Login = () => {
  return (
    <div className="w-screen h-screen bg-azblue flex items-center">
      <img src={logo} alt="Logo da AZConeX branca" className="fixed" />
      <LoginForm />
    </div>
  );
};

export default Login;
