import { ChevronRight, Plus, Settings2, Trash2 } from 'lucide-react';
import { Button } from '../../../ui/button';
import Menu from '../../../../interfaces/Menu';
import { useRef, useState } from 'react';
import { node } from './types';
import DeletionModal from './DeletionModal';

const Subtree = ({
  parent = 'root',
  menu,
  level = 0,
  subtrees,
  menuSelected,
  menuSelectedSetter,
  subtreesSetter,
}: {
  parent?: Menu | 'root';
  menu: Menu;
  level?: number;
  subtrees: string[];
  menuSelected: node;
  menuSelectedSetter: React.Dispatch<React.SetStateAction<node>>;
  subtreesSetter: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const [modalDisplay, setModalDisplay] = useState<boolean>(false);
  const settingsButton = useRef(null);
  const deleteButton = useRef(null);

  return (
    <ul
      key={menu.menu_name}
      className="flex flex-col"
      style={{ marginLeft: 16 * level }}
    >
      <div
        className="flex"
        onMouseOver={() =>
          ((settingsButton.current! as SVGElement).style.opacity = '1') &&
          ((deleteButton.current! as SVGElement).style.opacity = '1')
        }
        onMouseLeave={() =>
          ((settingsButton.current! as SVGElement).style.opacity = '0') &&
          ((deleteButton.current! as SVGElement).style.opacity = '0')
        }
      >
        <Button
          key={menu.menu_name}
          className={
            'justify-start px-2' +
            (menuSelected[1]?.id === menu.id
              ? ' bg-azblue text-white hover:bg-azblue hover:text-white'
              : '')
          }
          variant="ghost"
          size="sm"
          onClick={() =>
            subtrees.includes(menu.menu_name)
              ? subtreesSetter([
                  ...subtrees.filter((name) => name !== menu.menu_name),
                ])
              : subtreesSetter([...subtrees, menu.menu_name])
          }
        >
          <ChevronRight
            className={
              'transition-transform ' +
              (subtrees.includes(menu.menu_name) ? 'rotate-90' : 'rotate-0')
            }
          />
          {menu.menu_name}
        </Button>
        <Button
          variant="link"
          size="icon"
          className="border-0"
          onClick={() => menuSelectedSetter([parent, menu])}
          title="Editar menu"
        >
          <Settings2
            ref={settingsButton}
            className="transition-opacity opacity-0"
          />
        </Button>
        <Button
          variant="link"
          size="icon"
          className="border-0"
          onClick={() => {
            setModalDisplay(true);
          }}
          title="Deletar menu"
        >
          <Trash2 ref={deleteButton} className="transition-opacity opacity-0" />
        </Button>
      </div>
      <DeletionModal
        menu={menu}
        show={modalDisplay}
        setDisplay={setModalDisplay}
      />

      {subtrees.includes(menu.menu_name)
        ? [
            ...('submenus' in menu
              ? menu.submenus?.map((submenu) => (
                  <Subtree
                    key={submenu.id}
                    parent={menu}
                    menu={submenu}
                    level={level + 1}
                    subtrees={subtrees}
                    subtreesSetter={subtreesSetter}
                    menuSelectedSetter={menuSelectedSetter}
                    menuSelected={menuSelected}
                  />
                ))
              : []),
            <Button
              key={`${menu.menu_name}_add`}
              variant="ghost"
              size="sm"
              className={
                'justify-start font-semibold' +
                (menuSelected[0] !== 'root' &&
                menuSelected[0]?.id === menu.id &&
                !menuSelected[1]
                  ? ' bg-azblue text-white hover:bg-azblue hover:text-white'
                  : '')
              }
              style={{ marginLeft: 16 * level }}
              onClick={() => menuSelectedSetter([menu, null])}
            >
              <Plus /> Adicionar submenu
            </Button>,
          ]
        : null}
    </ul>
  );
};

export default Subtree;
