import { useMemo, useState } from 'react';
import IResponse from '../interfaces/Response';

const baseURL = process.env.REACT_APP_API_BASE_URL;

type HTTPMethods = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'UPDATE';

function useFetch<T>(
  url: string,
  method: HTTPMethods = 'GET',
  withCredentials: boolean = true,
  headers: Record<string, string> = {},
  controlLoading: boolean = false
): [
  (
    body?: Record<any, string | number | object | null>
  ) => Promise<IResponse<T>>,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
] {
  const [loading, setLoading] = useState<boolean>(false);

  const csrfToken = useMemo(
    () => window.sessionStorage.getItem('csrf_token') || '',
    []
  );

  const options: RequestInit = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      ...(withCredentials ? { 'X-CSRF-Token': csrfToken } : {}),
      ...headers,
    },
    credentials: withCredentials ? 'include' : 'same-origin',
  };

  const _fetch = async (
    body: Record<any, string | number | object | null> = {}
  ): Promise<IResponse<T>> => {
    setLoading(true);
    const updatedOptions = !['GET', 'DELETE'].includes(method)
      ? {
          ...options,
          body: JSON.stringify(body),
        }
      : options;

    let response: Response;
    try {
      response = await fetch(baseURL + url, updatedOptions);
    } catch (e) {
      setLoading(false);
      throw e;
    }

    if (!controlLoading) setLoading(false);
    if (response.status === 401)
      window.dispatchEvent(new Event('new-login-request'));

    let json;
    if (Number(response.headers.get('Content-Length')))
      json = await response.json();

    return {
      status: response.status,
      ok: response.ok,
      data: json as T,
    };
  };

  return [_fetch, loading, setLoading];
}

export { useFetch };
