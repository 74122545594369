import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { Button } from '../../ui/button';
import FormField from './FormField';
import { Form } from '../../ui/form';
import Header from './Header';

import Logo from '../../../assets/img/logo-AZCONEX-branca.svg';
import User from '../../../interfaces/User';
import { Toaster } from '../../ui/toaster';
import { useToast } from '../../../hooks/use-toast';
import { useFetch } from '../../../hooks/use-fetch';
import { SetUserContext } from '../../../App';

const formSchema = z.object({
  emailOrKey: z
    .string()
    .regex(
      /(?:^[\w.]+@azconex.com.br$)|^AZ[a-zA-Z0-9]{6}$/,
      'Email ou chave inválido!'
    )
    .min(1, 'E-mail é obrigatório!'),
  password: z.string().min(1, 'Senha é obrigatória!'),
});

const LoginForm = () => {
  const { toast } = useToast();
  const [validateUser, loading] = useFetch<{
    user_data: User;
    csrf_token: string;
  }>('/login', 'POST');
  const setUser = useContext(SetUserContext)!;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      emailOrKey: '',
      password: '',
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    const credentials = {
      email: values.emailOrKey,
      password: values.password,
    };

    validateUser(credentials)
      .then((res) => {
        if (!res.ok) {
          toast({
            title: 'Usuário não encontrado.',
            description:
              'Precisa de ajuda? Entre em contato com contratacao@azconex.com.br',
            variant: 'destructive',
            className: 'data-[state=closed]:slide-out-to-bottom-full',
          });
          return;
        }

        const user = res.data.user_data;
        setUser(user);
        window.sessionStorage.setItem('user', JSON.stringify(user));
        window.sessionStorage.setItem('csrf_token', res.data.csrf_token);
      })
      .catch(() => {
        toast({
          title: 'Erro no servidor :(',
          description:
            'Pedimos desculpas pelo transtorno. Tente novamente mais tarde.',
          variant: 'destructive',
        });
      });
  }

  return (
    <>
      <Form {...form}>
        <div
          className="w-fit bg-background/90 border-azblue p-10 m-auto
          z-10 border rounded-md backdrop-blur-sm"
        >
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col space-y-4"
          >
            <Toaster />
            <Header />
            <FormField
              name="emailOrKey"
              label="E-mail/Chave"
              placeholder="nome@azconex.com.br"
              form={form}
            />
            <FormField name="password" label="Senha" form={form} />
            <Button
              type="submit"
              className="relative self-end px-5 disabled:opacity-100 disabled:cursor-wait 
              disabled:pointer-events-auto disabled:hover:bg-primary"
              disabled={loading}
            >
              {loading ? (
                <div className="flex gap-2.5">
                  <img
                    src={Logo}
                    alt="Logo da AZConeX girando em sentido horário"
                    className="motion-safe:animate-[spin_3s_linear_infinite] w-4"
                  />
                  <p>Validando...</p>
                </div>
              ) : (
                'Entrar'
              )}
            </Button>
          </form>
        </div>
      </Form>
    </>
  );
};

export default LoginForm;
