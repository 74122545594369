import { useContext, useMemo } from 'react';
import lowOpLogo from '../../../assets/img/logo-AZCONEX-cinza-25op.svg';

import { Route, Routes } from 'react-router-dom';
import { UserContext } from '../../../App';
import User from '../../../interfaces/User';

import Modules from '../../../moduleRegister';

/**
 * @description Component to act as a placeholder for the viewport
 *              when no service was selected in the menu,
 *              corresponding to the base route `/`.
 */
const Root = (): JSX.Element => (
  <div className="flex flex-col gap-2.5 items-center m-auto w-fit">
    <img src={lowOpLogo} alt="Logo da AZConeX com baixa opacidade" />
    <p className="text-2xl text-[#989898]">
      Selecione um serviço no menu lateral
    </p>
  </div>
);

const Viewport = (): JSX.Element => {
  const { menus } = useContext(UserContext) as User;
  const routes = useMemo(
    () =>
      menus.map((m) => {
        const Module = Modules[m.module_name];
        if (Module)
          return (
            <Route
              key={m.menu_name}
              path={m.route + '/*'}
              element={<Module />}
            />
          );
        return null;
      }),
    [menus]
  );

  return (
    <div className="viewport w-full bg-transparent mr-2 p-1">
      <Routes>
        <Route path="/" element={<Root />} />
        {routes}
      </Routes>
    </div>
  );
};
export default Viewport;
